import './App.css';
import Chat from './component/Chat';
function App() {
  // fetch('https://dummyjson.com/products/1').then(
  //   function() {
      
  //   }
  // )
  
  return (
   <div className="main-div">

     <Chat/>
   </div>
      
   
  );
}

export default App;
